import Axios from './http';
import _ from 'lodash';
const prefix = '/consumer/v1';

export default class Target {
  static async setup(params) {
    return Axios(prefix + '/target/setup', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async list() {
    return Axios(prefix + '/target/list', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async history() {
    return Axios(prefix + '/target/history', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async result(params) {
    return Axios(prefix + '/target/result', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async daily(params) {
    return Axios(prefix + '/target/dailyResult', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
}
