<template>
  <div class="flex items-center space-x-4">
    <div>
      <DateRange
        v-model:start="modelTimeStart"
        v-model:end="modelTimeEnd"
        :disabled="disabled"
        :start-date="dayjs()"
      ></DateRange>

      <div class="flex mt-4 space-x-4 md:space-x-0 md:flex-wrap">
        <el-select
          v-model="modelSbjKey"
          :disabled="disabled"
          placeholder="選擇科目"
          class="md:w-full"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="item.selected"
          />
        </el-select>

        <div class="flex space-x-4 md:mt-4 md:w-full">
          <ElInputNumber
            v-model="modelQTotal"
            :disabled="disabled"
            placeholder="目標總題數 ( > 0 )"
            :min="1"
            :controls="false"
            class="flex-grow"
          ></ElInputNumber>

          <ElInputNumber
            v-model="modelCorrectRate"
            :disabled="disabled"
            placeholder="期望正確率 (1-100)"
            :min="1"
            :max="100"
            :controls="false"
            class="flex-grow"
          ></ElInputNumber>
        </div>
      </div>
    </div>
    <TrashBtn class="flex-shrink-0" @click="remove"></TrashBtn>
  </div>
</template>

<script>
import { ElOption, ElSelect, ElInputNumber, ElMessage } from 'element-plus';

import TrashBtn from '@/components/TrashBtn';
import DateRange from '@/components/form/DateRange.vue';
import { useVModels } from '@vueuse/core';
import dayjs from 'dayjs';

export default {
  components: {
    DateRange,
    ElOption,
    ElSelect,
    ElInputNumber,

    TrashBtn,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      required: true,
      type: Array,
    },

    timeStart: {
      type: String,
      default: '',
    },
    timeEnd: {
      type: String,
      default: '',
    },
    sbjKey: {
      type: String,
      default: '',
    },
    qTotal: {
      type: Number || Object,
      default: undefined,
    },
    correctRate: {
      type: Number || Object,
      default: undefined,
    },
  },
  emits: [
    'remove',
    'update:timeStart',
    'update:timeEnd',
    'update:sbjKey',
    'update:qTotal',
    'update:correctRate',
  ],

  setup(props, { emit }) {
    const {
      timeStart: modelTimeStart,
      timeEnd: modelTimeEnd,
      sbjKey: modelSbjKey,
      qTotal: modelQTotal,
      correctRate: modelCorrectRate,
    } = useVModels(props, emit);

    const remove = () => {
      emit('remove');
    };

    return {
      remove,
      modelTimeStart,
      modelTimeEnd,
      modelSbjKey,
      modelQTotal,
      modelCorrectRate,
      dayjs,
    };
  },
};
</script>

<style lang="sass" scoped>
:deep(.el-date-editor)
  --el-date-editor-width: 100%
</style>
