<template>
  <SetTarget />
</template>

<script>
import SetTarget from '@/modules/target/setup/Index.vue';
export default {
  components: { SetTarget },
};
</script>
