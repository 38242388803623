<template>
  <div class="">
    <div class="flex flex-col items-center">
      <div class="text-3xl">設定個人目標</div>
      <div class="text-lg mt-2">設定自己的目標，然後一步一步的完成</div>
      <div class="mt-6 bg-white shadow rounded p-8 md:w-full md:px-4">
        <div class="space-y-8">
          <div v-for="(item, index) in form" :key="index">
            <Setter
              v-model:timeStart="item.timeStart"
              v-model:timeEnd="item.timeEnd"
              v-model:sbjKey="item.sbjKey"
              v-model:qTotal="item.qTotal"
              v-model:correctRate="item.correctRate"
              :disabled="!!item.id"
              :options="subjectOpts"
              @remove="remove(index)"
            ></Setter>
          </div>
        </div>
        <div class="mt-6">
          <el-button
            :disabled="form.length >= LIMIT"
            type="info"
            size="small"
            @click="add"
          >
            + 新增目標
          </el-button>
        </div>
      </div>

      <div class="flex flex-col mt-8">
        <el-button type="primary" size="default" @click="submit"
          >完成設定</el-button
        >
        <el-button
          type="text"
          size="small"
          class="!ml-0 mt-2 text-gray"
          @click="$router.push({ path: '/assistant' })"
          >稍後再說</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import Setter from './setter.vue';
import Subject from '@/api/Subject.js';
import Target from '@/api/Target.js';

import { useRouter } from 'vue-router';
// import MessageBox from '@/components/messageBox/Index';
export default {
  components: {
    Setter,
  },
  setup() {
    const LIMIT = 20;
    // step1
    const defaultForm = {
      timeStart: null,
      timeEnd: null,
      sbjKey: '',
      qTotal: undefined,
      correctRate: undefined,
    };

    const form = reactive([]);

    const rawOpts = reactive([]);

    const fetcherSubjects = () => {
      Subject.all().then((res) => {
        if (res.code === 1) {
          rawOpts.push(...res.data.rows);
        }
      });
    };
    const subjectOpts = computed(() => {
      const keys = form.map((x) => {
        return x.sbjKey;
      });

      const opts = rawOpts.map((x) => {
        return {
          ...x,
          selected: keys.indexOf(x.id) > -1,
        };
      });
      return opts;
    });

    const add = () => {
      if (form.length < LIMIT) {
        form.push({ ...defaultForm });
      }
    };
    const remove = (idx) => {
      form.splice(idx, 1);
    };

    const fetcher = () => {
      Target.list().then((res) => {
        if (res.code === 1) {
          form.splice(0, form.length, ...res.data.rows);
        } else {
          add();
        }
      });
    };
    const router = useRouter();
    const submit = () => {
      // let sbj;
      const invalid = form.some((x) => {
        for (const prop in x) {
          if (!x[prop] && String(x[prop]) !== '0') {
            return true;
          }
        }
      });

      if (invalid) {
        ElMessage({ message: '請完整填寫資料' });
        return;
      }

      Target.setup({ targetScopes: form }).then((res) => {
        if (res.code === 1) {
          ElMessage({ message: '成功建立目標', type: 'success' });
          router.push({ name: 'Assistant' });
        }
      });
    };

    onMounted(() => {
      fetcher();
      fetcherSubjects();
    });

    return { form, subjectOpts, submit, add, remove, LIMIT };
  },
};
</script>
